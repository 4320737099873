<template>
  <div>
    <b-row>
      <b-col
        md="12"
        sm="12"
      >
        <b-card>

          <b-form

            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <b-row>
              <b-col md="4">

                <b-form-group
                  :label="$t('Başlangıç Ay/Yıl')"
                  label-for="target_year"
                >  <b-input-group>

                  <b-form-select
                    v-model="filterData.start_year"
                    :options="targetYears"
                  />
                  <b-input-group-append>
                    <b-form-select
                      v-model="filterData.start_month"
                      :options="targetMonts"
                      text-field="name"
                      value-field="number"
                    />
                  </b-input-group-append>
                </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  :label="$t('Bitiş Ay/Yıl')"
                  label-for="target_year"
                >  <b-input-group>

                  <b-form-select
                    v-model="filterData.end_year"
                    :options="targetYears"
                  />
                  <b-input-group-append>
                    <b-form-select
                      v-model="filterData.end_month"
                      :options="targetMonts"
                      text-field="name"
                      value-field="number"
                    />
                  </b-input-group-append>
                </b-input-group>
                </b-form-group>
              </b-col>

            </b-row>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="calculateData"
            >
              Hesapla ve Filtrele
            </b-button>
            <div class="d-flex mt-2">

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="fetchData"
              >
                Filtrele
              </b-button>
            </div>
          </b-form>

        </b-card>

      </b-col>
      <b-col
        v-if="reportData"
        md="12"
      >
        <b-row>
          <b-col md="3">
            <statistic-card-vertical
              color="secondary"
              icon="FlagIcon"
              :statistic="$staticParams.formatCurrency(reportData.totalExpense)+' '"
              statistic-title="Ürün Alışları Hariç Giderler Toplamı"
            />
          </b-col>
          <b-col md="3">
            <statistic-card-vertical
              color="secondary"
              icon="FlagIcon"
              :statistic="+$staticParams.formatCurrency(reportData.productExpenseTotal)"
              statistic-title="Ürün Alış Giderleri Toplamı"
            />
          </b-col>
          <b-col md="3">
            <statistic-card-vertical
              color="secondary"
              icon="FlagIcon"
              :statistic="$staticParams.formatCurrency(reportData.productIncludedTotalExpense)"
              statistic-title="Ürün Alışları Dahil Giderler Toplamı"
            />
          </b-col>
          <b-col md="3">
            <statistic-card-vertical
              color="secondary"
              icon="FlagIcon"
              :statistic="$staticParams.formatCurrency(reportData.totalEarning)"
              statistic-title="Ürün Alışları Dahil Toplam Kazanç"
            />
          </b-col>
          <b-col md="3">
            <statistic-card-vertical
              color="secondary"
              icon="FlagIcon"
              :statistic="'%'+reportData.totalEarningRate.toFixed(2)"
              statistic-title="Ürün Alışları Dahil Toplam Kazanç Oranı"
            />
          </b-col>
        </b-row>

        <b-row>

          <b-col md="12">
            <general-expense-table
              :filter-options="filterOptions"
            />
          </b-col>
          <b-col md="12">
            <marketing-expense-table
              :filter-options="filterOptions"
            />
          </b-col>
          <b-col md="12">
            <other-expense-table
              :filter-options="filterOptions"
            />
          </b-col>
        </b-row>
      </b-col>

    </b-row>
    <div />
  </div></template>

<script>
import {
  BCard, BRow, BCol, BButton, BForm, BFormGroup, BFormSelect, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import reportsStoreModule from '../reportsStoreModule'
import generalExpenseTable from './GeneralExpenseTable.vue'
import marketingExpenseTable from './MarketingExpenseTable.vue'
import otherExpenseTable from './OtherExpenseTable.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    generalExpenseTable,
    BFormSelect,
    otherExpenseTable,
    marketingExpenseTable,
    StatisticCardVertical,
    BButton,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      filterData: {
        seller_codes: [0],
        start_year: 2015,
        start_month: 0,
        end_year: 2015,
        end_month: 0,
        type: '',
      },
      reportData: null,

    }
  },
  computed: {
    filterOptions() {
      return {
        start_year: this.filterData.start_year,
        start_month: this.filterData.start_month,
        end_year: this.filterData.end_year,
        end_month: this.filterData.end_month,
        seller_code: '',
        location: '',
        buyer_name: '',
        product_code: '',

      }
    },
    targetMonts() {
      const arr = [{ number: 0, name: 'Tümü' }]
      this.$staticParams.monthNames.forEach(val => {
        arr.push(val)
      })
      return arr
    },

  },

  created() {
    const d = new Date()
    const thisYear = d.getFullYear()

    this.filterData.start_year = thisYear
    this.filterData.end_year = thisYear
    this.filterData.start_month = 11
    this.filterData.end_month = 11
    if (this.$route.query.month) {
      // eslint-disable-next-line radix
      this.filterData.target_month = parseInt(this.$route.query.month)
      // eslint-disable-next-line radix
      this.targetDetailMonth = parseInt(this.$route.query.month)
    }
    if (this.$route.query.year) {
      // eslint-disable-next-line radix
      this.filterData.start_year = parseInt(this.$route.query.year)
      this.targetDetailMonth = 0
    }
    if (this.$route.query.type) {
      if (this.$route.query.type === 'current') {
        this.targetDetailMonth = 13
      }
    } else {
      this.filterData.type = ''
    }
    this.fetchData()
  },

  methods: {
    showSuccessMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarılı'), {
        title: this.$t('İşlem Başarılı'),
        variant: 'success',
        solid: false,
      })
    },
    showErrorMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarısız'), {
        title: this.$t('İşlem Başarısız'),
        variant: 'danger',
        solid: false,
      })
    },
    fetchData() {
      store
        .dispatch('app-reports/fetchEarningExpenseTotals', this.filterData)
        .then(response => {
          this.reportData = response.data.data
        })
        .catch(() => {
        })
    },
    calculateData() {
      store
        .dispatch('app-reports/calculateReports', this.filterData)
        .then(() => {
          this.fetchData()
          this.showSuccessMessage()
        })
        .catch(() => {
        })
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-reports'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, reportsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    // eslint-disable-next-line no-unused-vars
    const targetsData = ref(null)
    const targetYear = ref(2015)
    const targetYearStart = ref(2015)
    const targetYears = ref([])
    const d = new Date()
    const thisYear = d.getFullYear()

    targetYear.value = thisYear
    const arrTarget = []
    // eslint-disable-next-line no-plusplus
    for (let i = targetYearStart.value; i <= thisYear + 1; i++) {
      arrTarget.push({ text: i, value: i })
    }
    targetYears.value = arrTarget

    const usersData = ref(null)
    store
      .dispatch('app/fetchReportUsers', {
        list_type: 'all',

      })
      .then(response => {
        const { data } = response.data
        const arr = []
        data.forEach(val => {
          arr.push({ label: `${val.seller_name} `, value: val.seller_code })
        })
        arr.unshift({ label: 'Tümü', value: 0 })
        usersData.value = arr
      })
      .catch(() => {
      })

    return {

      // Filter
      avatarText,
      usersData,
      targetYears,
      // UI
      // Extra Filters
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.vgt-table {
    font-size: 13px;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
